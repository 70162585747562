const Reports = Go.vue;

export default Reports;

const components = {
  general: () => import("./ReportGeneral.vue"),
  subscriptions_cancelled: () => import("./ReportSubscriptionsCancelled.vue"),
  payments: () => import("./ReportPayments.vue"),
};

Reports.components = {
  ReportsHeader: () => import("./ReportsHeader.vue"),
};

Reports.computed.hasReportComponent = function() {
  return this.$route.query.report in components;
};

Reports.computed.getReportComponent = function() {
  if (!this.hasReportComponent) {
    return components["general"];
  }

  return components[this.$route.query.report];
};
