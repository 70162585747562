<template>
  <div class="AdminReports">
    <ReportsHeader />
    <component :is="getReportComponent"></component>
  </div>
</template>

<script>
import AdminReports from "./AdminReports.js";
export default {
  mixins: [AdminReports],
};
</script>
